import React from 'react';

const Explore = () => {
  const handleExploreClick = () => {
    window.location.href = 'https://www.getmycollege.com/colleges?state=karnataka';
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
      }}
    >
      <button
        onClick={handleExploreClick}
        style={{
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#7098c2', // Blue color
          borderRadius: '25px', // More rounded edges
          padding: '15px 30px', // Bigger padding for a larger button
          boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s ease',
          border: 'none',
          cursor: 'pointer',
          fontSize: '16px', // Slightly larger text
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#003f8c')} // Green hover color
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#7098c2')} // Back to blue
      >
        Explore More >>>
      </button>
    </div>
  );
};

export default Explore;

import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ScrollToTopArrow = () => {
    const [visible, setVisible] = useState(false);

    // Show the button when scrolled 200px from the top
    const toggleVisibility = () => {
        if (window.scrollY > 200) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    // Scroll to top or the banner section when clicked
    const scrollToTop = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    React.useEffect(() => {
        // Listen for scroll events
        window.addEventListener('scroll', toggleVisibility);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div
            style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: 1000,
                display: visible ? 'block' : 'none',
            }}
        >
            <IconButton
                onClick={scrollToTop}
                style={{
                    backgroundColor: '#1d1a57', // Single color for background
                    color: '#fff', // Arrow color
                    borderRadius: '50%',
                    padding: '10px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                }}
            >
                <ArrowUpwardIcon />
            </IconButton>
        </div>
    );
};

export default ScrollToTopArrow;

import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Grid, Box, Drawer, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import PhoneIcon from '@mui/icons-material/Phone';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../images/logo.png';

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#7098c2' }}>
      <Toolbar>
        <Grid container alignItems="center">
          {/* Logo Section */}
          <Grid item xs={6} md={3}>
            <img
              src={logo}
              width={100}  // Increased width
              height={100}  // Increased height
              style={{ borderRadius: '30px', cursor: 'pointer' }}
              alt="Logo"
            />
          </Grid>

          {/* Navigation Links for Desktop */}
          <Grid item xs={9} sx={{ display: { md: 'flex', xs: 'none' }, justifyContent: 'flex-end' }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
              <Box display="flex" alignItems="center" gap={1}>
                <HomeIcon sx={{ color: '#000000', fontSize: 24 }} />
                <Typography
                  variant="h5"
                  sx={{
                    cursor: 'pointer',
                    color: '#000000',
                    '&:hover': { color: 'lightgray' },
                  }}
                >
                  Home
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ color: '#000000' }}>
                \
              </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <InfoIcon sx={{ color: '#000000', fontSize: 24 }} />
                <Typography
                  variant="h5"
                  onClick={() => (window.location.href = 'https://www.getmycollege.com/about-us')}
                  sx={{
                    cursor: 'pointer',
                    color: '#000000',
                    '&:hover': { color: 'lightgray' },
                  }}
                >
                  About Us
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ color: '#000000' }}>
                \
              </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <SchoolIcon sx={{ color: '#000000', fontSize: 24 }} />
                <Typography
                  variant="h5"
                  onClick={() => (window.location.href = 'https://www.getmycollege.com/colleges?state=karnataka')}
                  sx={{
                    cursor: 'pointer',
                    color: '#000000',
                    '&:hover': { color: 'lightgray' },
                  }}
                >
                  Architecture Colleges
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ color: '#000000' }}>
                \
              </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <PhoneIcon sx={{ color: '#000000', fontSize: 24 }} />
                <Typography
                  variant="h5"
                  onClick={() => (window.location.href = 'https://www.getmycollege.com/contact-us')}
                  sx={{
                    cursor: 'pointer',
                    color: '#000000',
                    '&:hover': { color: 'lightgray' },
                  }}
                >
                  Contact Us
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Menu Icon for Mobile */}
          <Grid item xs={6} sx={{ display: { md: 'none', xs: 'flex' }, justifyContent: 'flex-end' }}>
            <IconButton onClick={toggleMenu}>
              <MenuIcon sx={{ color: '#000000' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>

      {/* Mobile Menu Drawer */}
      <Drawer anchor="right" open={openMenu} onClose={toggleMenu}>
        <Box sx={{ width: 250, padding: 2 }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography
              variant="h6"
              onClick={() => (window.location.href = '/')}
              sx={{
                cursor: 'pointer',
                color: '#000000',
                '&:hover': { color: 'lightgray' },
              }}
            >
              Home
            </Typography>
            <Typography
              variant="h6"
              onClick={() => (window.location.href = 'https://www.getmycollege.com/about-us')}
              sx={{
                cursor: 'pointer',
                color: '#000000',
                '&:hover': { color: 'lightgray' },
              }}
            >
              About Us
            </Typography>
            <Typography
              variant="h6"
              onClick={() => (window.location.href = 'https://www.getmycollege.com/colleges?state=karnataka')}
              sx={{
                cursor: 'pointer',
                color: '#000000',
                '&:hover': { color: 'lightgray' },
              }}
            >
              Architecture Colleges
            </Typography>
            <Typography
              variant="h6"
              onClick={() => (window.location.href = 'https://www.getmycollege.com/contact-us')}
              sx={{
                cursor: 'pointer',
                color: '#000000',
                '&:hover': { color: 'lightgray' },
              }}
            >
              Contact Us
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;

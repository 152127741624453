import React, { useState } from 'react';
import './Cards.css';

const names = [
  "CMR Institute",
  "BMS Institute",
  "MSRIT",
  "PES University",
];

// Array of image URLs
const images = [
  "https://imgs.search.brave.com/vW1wrhTFhV_KXcX9zQ-54xEvzmksN1hPTMNnnPQLjsA/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly93d3cu/aWVzb25saW5lLmNv/LmluL2NvbGxlZ2Vz/LWltYWdlL2Ntci11/bml2ZXJzaXR5Lmpw/Zw",
  "https://imgs.search.brave.com/uXD586A_f6oX6eiqAc-EmwOM9AheOc7KEPjV5p7WQxA/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9kMTNs/b2FydGpvYzF5bi5j/bG91ZGZyb250Lm5l/dC91cGxvYWQvaW5z/dGl0dXRlL2ltYWdl/cy9sYXJnZS8yMDA2/MjkwOTQ1NThfQWJv/dXQtQk1TLUNvbGxl/Z2UtQXJjaGl0ZWN0/dXJlLndlYnA",
  "https://media.collegedekho.com/media/img/institute/crawled_images/50_big.jpg?width=640",
  "https://imgs.search.brave.com/YRmd_TbFZBRf27ynsyjufzuCRc29sc6RS1SOk0xMe7k/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy8y/LzI4L1Blc2l0X0Et/YmxvY2suanBn",
];

// Array of URLs for each button
const links = [
  "https://www.getmycollege.com/colleges?state=karnataka",
  "https://www.getmycollege.com/colleges?state=karnataka",
  "https://www.getmycollege.com/colleges?state=karnataka",
  "https://www.getmycollege.com/colleges?state=karnataka",
];

const Cards = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  return (
    <div className="card-container">
      {names.map((name, index) => (
        <div
          key={index}
          className={`card ${hoveredCard === index ? "hovered" : ""}`}
          onMouseEnter={() => setHoveredCard(index)}
          onMouseLeave={() => setHoveredCard(null)}
        >
          {/* Card content */}
          <div className="card-image-container">
            <img src={images[index]} alt={name} className="card-image" />
          </div>
          <div className="card-name">{name}</div>
          {/* Button container */}
          <div className="button-container">
            <a href={links[index]} target="_blank" rel="noopener noreferrer">
              <button className="view-more-button">View More</button>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Cards;



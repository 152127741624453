import React from 'react';
import { Container, Box, Typography } from '@mui/material';


const Footer = () => {
  return (
    <div>
      {/* Footer Bottom Section */}
      <Box
        style={{
          backgroundColor: '#7098c2',
          padding: '10px 0',
          position: 'relative', // To position WhatsApp icon above the line
        }}
      >
        <Container maxWidth="xl"> {/* Increased container size */}
          <Box display="flex" justifyContent="space-between" alignItems="center" position="relative">
            {/* Center Section: Copyright */}
            <Typography
              variant="body2"
              style={{
                color: 'white',
                textAlign: 'center',  // Center-align the copyright text
                width: '100%',         // Take full width of container
              }}
            >
              ©️ Copyright. All rights reserved.
            </Typography>

            {/* Right Section: WhatsApp Icon */}
            <Box
              style={{
                position: 'absolute',
                right: '50px',
                top: '-20px', // Adjust this value to move the WhatsApp logo above the line
              }}
            >
              
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Footer;

import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/Header';
import Banner from './components/Banner';
import Heading from './components/Heading';
import Home from './components/Home';
import Cards from './components/Cards'; // Import the Cards component
import Explore from './components/Explore';
import Demo1 from './components/Forms'; // Import your Demo1 component
import { useEffect, useState } from 'react';
import AutoPopup from './components/AutoPopup';
import AboutUs from './components/AboutUs';
import Aboutus from './components/Aboutheading';
import ScrollToTopArrow from './components/ScrollArrow';
function App() {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);
  const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };
  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 5000);

    return () => clearTimeout(timeout);
  },[])

  return (
    <div>
      <Navbar />
      <Banner />
      <Heading />
      <Cards />
      <Explore />
      <Demo1 />
      <Aboutus />
      <AboutUs />
      <ScrollToTopArrow />
      <Home />

      <AutoPopup
        isOpen={isAutoPopupOpen}
        onClose={handleCloseAutoPopup}

      />
    </div>
  );
}

export default App;
import React from 'react';

const Heading = () => {
  return (
    <div className="heading-container">
      <h2 className="heading-text">Leading Architecture Colleges</h2>
      {/* Blue paragraph line */}
      <p className="blue-line"></p>
      <p className="sub-heading-text">Explore our best architecture colleges and programs designed for your success.</p>
    </div>
  );
};

export default Heading;

// CSS in the same file
const style = `
  .heading-container {
    text-align: center;
    margin: 30px 0; /* Increased margin for better spacing */
  }
  
  .heading-text {
    font-weight: 900; /* Bold text */
    background: linear-gradient(to right, #2E4B71, #ffffff); /* Gradient color */
    -webkit-background-clip: text; /* Apply gradient to text */
    color: transparent; /* Make text transparent to show gradient */
    font-size: 2.5rem; /* Increase font size for a bigger text */
    letter-spacing: 2px; /* Optional: add letter spacing for effect */
  }

  .sub-heading-text {
    font-size: 1.2rem; /* Slightly smaller font size for the paragraph */
    color: #2E4B71; /* Blue color for the text */
    margin-top: 10px; /* Add some spacing from the heading */
  }

  /* Blue paragraph line */
  .blue-line {
    width: 50%; /* Decrease width to 50% of the container */
    height: 4px; /* Height of the line */
    background-color: #2E4B71; /* Blue color for the line */
    margin-top: 10px; /* Space between heading and line */
    margin-bottom: 20px; /* Optional: space after the line */
    margin-left: auto; /* Center the line horizontally */
    margin-right: auto; /* Center the line horizontally */
  }

  /* Mobile-specific styles */
  @media (max-width: 600px) {
    .heading-text {
      font-size: 1.8rem; /* Reduced font size for mobile */
    }

    .sub-heading-text {
      font-size: 1rem; /* Smaller font for mobile */
    }

    .blue-line {
      width: 70%; /* Slightly wider line on smaller screens for better balance */
    }
  }
`;

document.head.insertAdjacentHTML('beforeend', `<style>${style}</style>`);



import React from 'react';
import { Grid, Box } from '@mui/material';




import Footer from './footer'; // Make sure Footer is also imported correctly

const Home = () => {
  return (
    <Grid container direction="column">

     

      
     

     
      <Box sx={{ marginTop: '30px' }}>
        
      </Box>

    
      <Footer />
    </Grid>
  );
};

export default Home;

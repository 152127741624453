import React from 'react';

const Aboutus = () => {
  return (
    <div className="heading-container">
      <h2 className="heading-text">Architecture Colleges in Bangalore</h2>
      
    </div>
  );
};

export default Aboutus;

const style = `
  .heading-container {
    text-align: center;
    margin: 50px 0; /* Increased margin to move it further down */
  }
  
  .heading-text {
    font-weight: 900; /* Bold text */
    background: linear-gradient(to right, #2E4B71, #ffffff); /* Gradient color */
    -webkit-background-clip: text; /* Apply gradient to text */
    color: transparent; /* Make text transparent to show gradient */
    font-size: 2.5rem; /* Increase font size for a bigger text */
    letter-spacing: 2px; /* Optional: add letter spacing for effect */
  }

  
`;

document.head.insertAdjacentHTML('beforeend', `<style>${style}</style>`);


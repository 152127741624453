import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    Typography,
    TextField,
    Snackbar,
    Alert,
    Box,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import CancelIcon from "@mui/icons-material/Cancel";
import popform from "../images/popup.jpeg";

const AutoPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isNameValid, setIsNameValid] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(() => {
        const hasPopupShown = localStorage.getItem("popupShown");
        console.log("Popup already shown:", hasPopupShown);

        if (!hasPopupShown) {
            const timer = setTimeout(() => {
                console.log("Showing popup...");
                setIsOpen(true);
                localStorage.setItem("popupShown", "true");
            }, 4000); // Show popup after 4 seconds

            return () => clearTimeout(timer);
        }
    }, []);

    const handleClose = () => {
        console.log("Popup closed");
        setIsOpen(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isNameValid || !isPhoneValid) {
            showSnackbar("Please fill in all required fields.", "error");
            return;
        }

        showSnackbar("Form submitted successfully!", "success");
        setName("");
        setPhone("");
        handleClose();
    };

    return (
        <>
            <Dialog
                open={isOpen}
                PaperProps={{
                    sx: {
                        border: "5px solid #1d1a57",
                        borderRadius: "8px",
                        overflow: "hidden",
                    },
                }}
                maxWidth="md"
                sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        top: "0.5%",
                        right: "2%",
                    }}
                >
                    <CancelIcon sx={{ fontSize: "35px", color: "#7098c2" }} />
                </IconButton>

                <DialogContent>
                    <Grid container>
                        <Grid
                            item
                            md={6}
                            sx={{
                                display: { xs: "none", md: "block" },
                                backgroundImage: `url(${popform})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        />
                        <Grid item xs={12} md={6}>
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
                                    Apply for Admission
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setIsNameValid(!!e.target.value);
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ mb: 2 }} // Added marginBottom for spacing
                                />
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    value={phone}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/\D/g, "").slice(0, 10);
                                        setPhone(numericInput);
                                        setIsPhoneValid(numericInput.length === 10);
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ mb: 2 }} // Added marginBottom for spacing
                                />
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AutoPopup;

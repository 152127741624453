import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Container, Box } from '@mui/material';
import { Star, StarHalf, StarOutline } from '@mui/icons-material';

const Demo1 = () => {
  const [inView, setInView] = useState(false); // State to track if the section is in view
  const sectionRef = useRef(null); // Reference to the "Why Choose Us" section

  // Function to render the stars
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {[...Array(fullStars)].map((_, index) => (
          <Star key={`full-${index}`} style={{ color: '#FFD700' }} />
        ))}
        {halfStar && <StarHalf style={{ color: '#FFD700' }} />}
        {[...Array(emptyStars)].map((_, index) => (
          <StarOutline key={`empty-${index}`} style={{ color: '#FFD700' }} />
        ))}
      </>
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true); // Trigger the transition effect when the section is in view
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the section is in view
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current); // Start observing the section
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current); // Clean up when the component unmounts
      }
    };
  }, []);

  return (
    <Grid container spacing={4} sx={{ marginTop: 4 }}>
      {/* Why Choose Us Section */}
      <Grid item xs={12}>
        <Container
          ref={sectionRef}
          sx={{
            backgroundColor: '#87A9D0', // Background color for the container
            borderRadius: 5,
            padding: 4,
            maxWidth: '90%', // Adjusted width for the container
            margin: '0 auto',
            color: '#fff', // White text color
            textAlign: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            opacity: inView ? 1 : 0, // Controls visibility for fade-in effect
            transform: inView ? 'translateY(0)' : 'translateY(20px)', // Adds a slight upward movement
            transition: 'opacity 1s ease, transform 1s ease', // Smooth transition for opacity and transform
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
            Why Choose Us?
          </Typography>
          <Typography variant="h6" paragraph>
            Choose us for unmatched quality, reliability, and personalized service that puts your needs first. We focus on delivering innovative solutions and results that consistently exceed expectations. Our dedicated team is committed to ensuring your success, providing expert guidance and support every step of the way.
          </Typography>

          {/* Ratings Section */}
          <Box sx={{ marginTop: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  NIRF - India
                </Typography>
                {renderStars(4.5)}
              </Box>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Archinect
                </Typography>
                {renderStars(4)}
              </Box>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  EduRank
                </Typography>
                {renderStars(3.5)}
              </Box>
            </Box>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Demo1;


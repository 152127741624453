import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid } from '@mui/material';
import aboutus from '../images/aboutus.webp'; // Example image

const AboutUs = () => {
  const [open, setOpen] = useState(false); // Dialog open state
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState(''); // State for email
  const [inView, setInView] = useState(false); // State to track if the section is in view
  const sectionRef = useRef(null); // Reference to the About Us section

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    console.log('Name:', name);
    console.log('Phone:', phone);
    console.log('Email:', email); // Log email
    setOpen(false); // Close the dialog after form submission
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true); // Trigger the transition effect when the section is in view
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the section is in view
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current); // Start observing the section
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current); // Clean up when the component unmounts
      }
    };
  }, []);

  return (
    <Container
      ref={sectionRef}
      sx={{
        maxWidth: '100%',
        paddingY: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 4,
        opacity: inView ? 1 : 0, // Controls visibility for fade-in effect
        transform: inView ? 'translateY(0)' : 'translateY(20px)', // Adds a slight upward movement
        transition: 'opacity 1s ease, transform 1s ease', // Smooth transition for opacity and transform
        '@media (max-width: 768px)': {
          flexDirection: 'column',
          textAlign: 'center',
        },
      }}
    >
      {/* Left Image with Wave Border */}
      <Box
        sx={{
          width: '50%',
          height: 400,
          backgroundImage: `url(${aboutus})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          clipPath: 'polygon(0 0, 85% 0, 100% 20%, 100% 80%, 85% 100%, 0 100%)',
          '@media (max-width: 768px)': {
            width: '100%',
            height: 300,
            clipPath: 'polygon(0 0, 90% 0, 100% 25%, 100% 75%, 90% 100%, 0 100%)',
          },
        }}
      ></Box>

      {/* Right Text Content */}
      <Box
        sx={{
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '@media (max-width: 768px)': {
            width: '100%',
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: '#4a4a4a',
            marginBottom: 4,
          }}
        >
          We are a dedicated team committed to providing innovative, customized solutions for every client. With expertise across a wide range of industries, we deliver reliable and efficient outcomes. Our customer-first approach ensures that your needs and goals are always at the forefront. We prioritize quality, excellence, and attention to detail in every project. By working closely with you, we aim to exceed expectations and drive success. Our passion for continuous improvement keeps us ahead of industry trends. We believe in building long-term relationships based on trust and results.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#7098c2',
            color: '#fff',
            paddingX: 4,
            borderRadius: 20,
            '&:hover': {
              backgroundColor: '#1d1a57',
            },
          }}
          onClick={handleClickOpen}
        >
          Apply Now
        </Button>
      </Box>

      {/* Dialog Form */}
      <Dialog open={open} onClose={handleClose}>
  <DialogTitle sx={{ backgroundColor: '#fff', color: '#87A9D0', fontWeight: 'bold', borderRadius: '30px 30px 0 0' }}>
    Apply for Admissions
  </DialogTitle>
  <DialogContent sx={{ backgroundColor: '#fff', borderRadius: '0 0 30px 30px' }}>
    <TextField
      label="Name"
      variant="outlined"
      fullWidth
      value={name}
      onChange={(e) => setName(e.target.value)}
      sx={{ marginBottom: 2 }}
    />
    <TextField
      label="Phone Number"
      variant="outlined"
      fullWidth
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      sx={{ marginBottom: 2 }}
    />
    <TextField
      label="Email"
      variant="outlined"
      fullWidth
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      sx={{ marginBottom: 2 }}
    />
  </DialogContent>
  <DialogActions sx={{ backgroundColor: '#fff', borderRadius: '0 0 30px 30px' }}>
    <Button onClick={handleClose} variant="outlined" sx={{ color: '#87A9D0', borderRadius: 20 }}>
      Cancel
    </Button>
    <Button onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#87A9D0', color: '#fff', borderRadius: 20 }}>
      Submit
    </Button>
  </DialogActions>
</Dialog>

        
    </Container>
  );
};

export default AboutUs;

import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, Typography, TextField } from '@mui/material';
import banner from '../images/banner.webp';
import banner2 from '../images/banner2.jpg';
import banner3 from '../images/banner3.jpg';
import banner4 from '../images/banner4.webp';

const Banner = () => {
  const [currentBanner, setCurrentBanner] = useState(0);
  const banners = [banner, banner2, banner3, banner4];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentBanner((prevIndex) => (prevIndex + 1) % banners.length);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundImage: `url(${banners[currentBanner]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '500px',
        width: '100%',
        transition: 'background-image 1s ease-in-out',
      }}
    >
      <Typography
        variant="h2"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          color: '#fff',
          fontWeight: 'bold',
          zIndex: 10,
          animation: 'fadeIn 1s ease-in-out',
          display: 'flex',
          flexWrap: 'wrap',
          fontSize: '2.8rem', 
        }}
      >
        {['Top', 'Architecture', 'Colleges'].map((word, index) => (
          <Box
            key={index}
            component="span"
            sx={{
              opacity: 0,
              animation: `fadeInWord ${1 + index * 0.5}s forwards`,
              display: 'inline-block',
              ml: 15,
              mr:2,
              ml: index === 0 ? 15 : 0,
            }}
          >
            {word}
          </Box>
        ))}
      </Typography>

      <Button
        onClick={() => setCurrentBanner((prevIndex) => (prevIndex - 1 + banners.length) % banners.length)}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          background: 'rgba(0, 0, 0, 0.5)',
          color: '#fff',
          borderRadius: '50%',
          minWidth: '40px',
          minHeight: '40px',
        }}
      >
        &#8592;
      </Button>

      <Button
        onClick={() => setCurrentBanner((prevIndex) => (prevIndex + 1) % banners.length)}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          background: 'rgba(0, 0, 0, 0.5)',
          color: '#fff',
          borderRadius: '50%',
          minWidth: '40px',
          minHeight: '40px',
        }}
      >
        &#8594;
      </Button>

      <Grid
        container
        sx={{
          position: 'absolute',
          right: { md: '15%', xs: '5%' },
          top: '50%',
          transform: 'translateY(-50%)',
          backgroundColor: 'white',
          p: { md: 4, xs: 2 },
          width: { md: '400px', xs: '90%' },
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: '#000', textAlign: 'center', mb: 2 }}
        >
          May I Help You?
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleChange}
            label="Your Name"
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            label="Your Phone"
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="message"
            value={formData.message}
            onChange={handleChange}
            label="Your Message"
            variant="outlined"
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#7098c2',
              '&:hover': { backgroundColor: '#1d1a57' },
            }}
          >
            Submit
          </Button>
        </form>
      </Grid>

      <style>
        {`
          @keyframes fadeInWord {
            0% {
              opacity: 0;
              transform: translateX(-20px);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Banner;
